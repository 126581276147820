import React, { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import { RideService } from '../../types/RideshareService';
import axios from 'axios';
import API_BASE_URL from '../../config';

interface QuoteCalculatorProps {
  pickup: string;
  dropoff: string;
  isRoundTrip: boolean;
  onQuoteCalculated: (
    quote: number | null, 
    originalQuote: number | null, 
    error: string | null,
    distance: number | null,
    duration: number | null,
    promoCodeDetails: any | null  // Add this line
  ) => void;
  onCalculationStart: () => void;
  selectedService: RideService | null;
  promoCode: string;
  shouldCalculate: boolean;
}

const QuoteCalculator: React.FC<QuoteCalculatorProps> = ({
  pickup,
  dropoff,
  isRoundTrip,
  onQuoteCalculated,
  onCalculationStart,
  selectedService,
  promoCode,
  shouldCalculate
}) => {
  const calculatePrice = (service: RideService, miles: number, minutes: number, isRoundTrip: boolean) => {
    let total = 0; // Start from 0 instead of service.basePrice

    // Sort pricing components by order
    const sortedComponents = [...service.pricingComponents].sort((a, b) => (a.order || 0) - (b.order || 0));

    for (const component of sortedComponents) {
      if (component.applyToRoundTripOnly && !isRoundTrip) continue;

      let componentValue = 0;
      switch (component.type) {
        case 'flat':
          componentValue = component.value;
          break;
        case 'perMile':
          componentValue = component.value * miles;
          break;
        case 'perMinute':
          componentValue = component.value * minutes;
          break;
        case 'percentage':
          componentValue = total * (component.value / 100);
          break;
      }

      if (component.isDiscount) {
        total -= componentValue;
      } else {
        total += componentValue;
      }
    }

    // At the end of the function, ensure the total is not less than the minimum price
    return Math.max(total, service.minimumPrice);
  };

  const calculateQuote = useCallback(async () => {
    if (!pickup || !dropoff || !selectedService) return;

    onCalculationStart();

    try {
      console.log("Calculating quote");
      const response = await axios.post(`${API_BASE_URL}/rides/calculate-quote`, {
        pickup,
        dropoff,
        isRoundTrip,
        serviceId: selectedService.id,
      });

      console.log("Quote calculation response:", response.data);
      const { distance, duration, quote, originalQuote } = response.data;

      onQuoteCalculated(
        quote,
        originalQuote || quote,
        null,
        distance,
        duration,
        null
      );
    } catch (error) {
      console.error("Error calculating quote:", error);
      onQuoteCalculated(null, null, "Failed to calculate quote", null, null, null);
    }
  }, [pickup, dropoff, isRoundTrip, selectedService, onCalculationStart, onQuoteCalculated]);

  useEffect(() => {
    if (shouldCalculate) {
      calculateQuote();
    }
  }, [shouldCalculate, calculateQuote]);

  return null; // This component doesn't render anything
};

export default QuoteCalculator;