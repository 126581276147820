// src/components/admin/Admin.tsx

import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, Container, useMediaQuery, Theme } from '@mui/material';
import Dashboard from './Dashboard';
import RideManagement from './RideManagement';
import UserManagement from './UserManagement';
import RideServiceManagement from './RideServiceManagement';
import PromoCodeManagement from './PromoCodeManagement';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Admin: React.FC = () => {
  const [value, setValue] = useState(0);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center' }}>
          <Tabs value={value} onChange={handleChange} aria-label="admin tabs" centered>
            <Tab label="Dashboard" />
            <Tab label="Ride Management" />
            <Tab label="User Management" />
            <Tab label="Ride Service Management" />
            <Tab label="Promo Code Management" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Dashboard />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RideManagement />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <UserManagement />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <RideServiceManagement />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <PromoCodeManagement />
        </TabPanel>
      </Box>
    </Container>
  );
};

export default Admin;