import React, { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { SelectChangeEvent } from '@mui/material';
import { 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Checkbox, 
  ListItemText, 
  FormControlLabel
} from '@mui/material';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

interface PromoCode {
  id: string;
  code: string;
  description: string;
  discountType: 'percentage' | 'fixed';
  discountValue: number;
  expiresAt: string;
  usageLimit: number;
  usedCount: number;
  isActive: boolean;
  applicableServices: string[];
  neverExpires: boolean;
  noUsageLimit: boolean;
}

interface RideService {
  id: string;
  name: string;
}

const PromoCodeManagement: React.FC = () => {
  const [promoCodes, setPromoCodes] = useState<PromoCode[]>([]);
  const [rideServices, setRideServices] = useState<RideService[]>([]);
  const [open, setOpen] = useState(false);
  const [editingPromo, setEditingPromo] = useState<PromoCode | null>(null);
  const [formValues, setFormValues] = useState<Omit<PromoCode, 'id' | 'usedCount'>>({
    code: '',
    description: '',
    discountType: 'percentage',
    discountValue: 0,
    expiresAt: '',
    usageLimit: 0,
    isActive: true,
    applicableServices: [],
    neverExpires: false,
    noUsageLimit: false,
  });

  useEffect(() => {
    fetchPromoCodes();
    fetchRideServices();
  }, []);

  const fetchPromoCodes = async () => {
    const promoSnapshot = await getDocs(collection(db, 'promoCodes'));
    const promos: PromoCode[] = promoSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    })) as PromoCode[];
    setPromoCodes(promos);
  };

  const fetchRideServices = async () => {
    const servicesSnapshot = await getDocs(collection(db, 'rideServices'));
    const servicesList = servicesSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
    setRideServices(servicesList);
  };

  const handleOpen = (promo?: PromoCode) => {
    if (promo) {
      setEditingPromo(promo);
      setFormValues({
        code: promo.code,
        description: promo.description,
        discountType: promo.discountType,
        discountValue: promo.discountValue,
        expiresAt: promo.expiresAt,
        usageLimit: promo.usageLimit,
        isActive: promo.isActive,
        applicableServices: promo.applicableServices,
        neverExpires: promo.neverExpires,
        noUsageLimit: promo.noUsageLimit,
      });
    } else {
      setEditingPromo(null);
      setFormValues({
        code: '',
        description: '',
        discountType: 'percentage',
        discountValue: 0,
        expiresAt: '',
        usageLimit: 0,
        isActive: true,
        applicableServices: [],
        neverExpires: false,
        noUsageLimit: false,
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingPromo(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value, type, checked } = e.target as HTMLInputElement;
    if (type === 'checkbox') {
      setFormValues(prev => ({ ...prev, [name]: checked }));
    } else {
      setFormValues(prev => ({ ...prev, [name as string]: value }));
    }
  };

  const handleSubmit = async () => {
    try {
      const promoData = {
        ...formValues,
        expirationDate: formValues.neverExpires ? null : formValues.expiresAt,
        maxUses: formValues.noUsageLimit ? null : formValues.usageLimit,
      };

      if (editingPromo) {
        // Update existing promo
        const promoRef = doc(db, 'promoCodes', editingPromo.id);
        await updateDoc(promoRef, promoData);
      } else {
        // Create new promo
        await addDoc(collection(db, 'promoCodes'), promoData);
      }
      fetchPromoCodes();
      handleClose();
    } catch (error) {
      console.error("Error saving promo code:", error);
    }
  };

  const handleDelete = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this promo code?")) {
      try {
        await deleteDoc(doc(db, 'promoCodes', id));
        fetchPromoCodes();
      } catch (error) {
        console.error("Error deleting promo code:", error);
      }
    }
  };

  return (
    <Paper sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Promo Code Management
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpen()}
        startIcon={<AddIcon />}
        sx={{ mb: 2 }}
      >
        Add New Promo Code
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Discount Type</TableCell>
              <TableCell>Discount Value</TableCell>
              <TableCell>Expires At</TableCell>
              <TableCell>Usage Limit</TableCell>
              <TableCell>Used Count</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Applicable Services</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {promoCodes.map((promo) => (
              <TableRow key={promo.id}>
                <TableCell>{promo.code}</TableCell>
                <TableCell>{promo.description}</TableCell>
                <TableCell>{promo.discountType}</TableCell>
                <TableCell>{promo.discountValue}</TableCell>
                <TableCell>
                  {promo.neverExpires ? 'Never Expires' : promo.expiresAt}
                </TableCell>
                <TableCell>
                  {promo.noUsageLimit ? 'No Limit' : promo.usageLimit}
                </TableCell>
                <TableCell>{promo.usedCount}</TableCell>
                <TableCell>{promo.isActive ? 'Yes' : 'No'}</TableCell>
                <TableCell>{promo.applicableServices.join(', ')}</TableCell>
                <TableCell>
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={() => handleOpen(promo)}
                    sx={{ mr: 1 }}
                  >
                    Edit
                  </Button>
                  <Button 
                    variant="outlined" 
                    color="error" 
                    onClick={() => handleDelete(promo.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for Adding/Editing Promo Codes */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{editingPromo ? 'Edit Promo Code' : 'Add New Promo Code'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Code"
            name="code"
            value={formValues.code}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            label="Description"
            name="description"
            value={formValues.description}
            onChange={handleChange}
            fullWidth
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Discount Type</InputLabel>
            <Select
              name="discountType"
              value={formValues.discountType}
              onChange={(event: SelectChangeEvent<"percentage" | "fixed">) => handleChange(event as React.ChangeEvent<HTMLInputElement>)}
              label="Discount Type"
            >
              <MenuItem value="percentage">Percentage</MenuItem>
              <MenuItem value="fixed">Fixed Amount</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Discount Value"
            name="discountValue"
            type="number"
            value={formValues.discountValue}
            onChange={handleChange}
            fullWidth
            required
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.neverExpires}
                onChange={handleChange}
                name="neverExpires"
              />
            }
            label="Never Expires"
          />
          {!formValues.neverExpires && (
            <TextField
              margin="dense"
              name="expirationDate"
              label="Expiration Date"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={formValues.expiresAt}
              onChange={handleChange}
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.noUsageLimit}
                onChange={handleChange}
                name="noUsageLimit"
              />
            }
            label="No Usage Limit"
          />
          {!formValues.noUsageLimit && (
            <TextField
              margin="dense"
              name="maxUses"
              label="Max Uses"
              type="number"
              fullWidth
              value={formValues.usageLimit}
              onChange={handleChange}
            />
          )}
          <FormControl fullWidth margin="dense">
            <InputLabel>Applicable Services</InputLabel>
            <Select
              multiple
              name="applicableServices"
              value={formValues.applicableServices}
              onChange={(event: SelectChangeEvent<string[]>) => {
                const { target: { value } } = event;
                handleChange({
                  target: { name: "applicableServices", value }
                } as React.ChangeEvent<HTMLInputElement>);
              }}
              renderValue={(selected) => (selected as string[]).join(', ')}
            >
              {rideServices.map((service) => (
                <MenuItem key={service.id} value={service.name}>
                  <Checkbox checked={formValues.applicableServices.indexOf(service.name) > -1} />
                  <ListItemText primary={service.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {editingPromo ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default PromoCodeManagement;