// functions/src/components/RideForm.tsx

import React, { useState, useCallback, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase';
import { collection, getDocs, addDoc, updateDoc, doc } from 'firebase/firestore';
import LocationInput from './LocationInput';
import QuoteCalculator from './QuoteCalculator';
import { TextField, Checkbox, FormControlLabel, Button, Box, Typography, Container, Paper, Select, MenuItem, InputLabel, FormControl, CircularProgress, Grid } from '@mui/material';
import { RideService } from '../../types/RideshareService';
import PaymentStep from './PaymentStep';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { RideDetails } from '../../types/RideshareService';
import axios from 'axios';
import API_BASE_URL from '../../config';
// Load your Stripe publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

interface RideFormProps {
  onSubmit: (rideDetails: RideDetails) => void;
}

const RideForm: React.FC<RideFormProps> = ({ onSubmit }) => {
  const { user } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [originalRideDetails, setOriginalRideDetails] = useState<RideDetails | null>(null);

  const [pickup, setPickup] = useState('');
  const [dropoff, setDropoff] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [minDate, setMinDate] = useState('');
  const [minTime, setMinTime] = useState('');
  const [isRoundTrip, setIsRoundTrip] = useState(false);
  const [returnTime, setReturnTime] = useState('');
  const [additionalAssistance, setAdditionalAssistance] = useState('');
  const [selectedService, setSelectedService] = useState<RideService | null>(null);
  const [quote, setQuote] = useState<number | null>(null);
  const [originalQuote, setOriginalQuote] = useState<number | null>(null);
  const [distance, setDistance] = useState<number | null>(null);
  const [duration, setDuration] = useState<number | null>(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [rideServices, setRideServices] = useState<RideService[]>([]);
  const [bookingStep, setBookingStep] = useState<'details' | 'payment'>('details');
  const [rideDetails, setRideDetails] = useState<RideDetails | null>(null);
  const [rideId, setRideId] = useState<string | null>(null);
  const [promoCode, setPromoCode] = useState('');
  const [isApplyingPromo, setIsApplyingPromo] = useState(false);
  const [promoError, setPromoError] = useState<string | null>(null);
  const [appliedPromoDetails, setAppliedPromoDetails] = useState<any | null>(null);

  const [shouldCalculate, setShouldCalculate] = useState(false);

  const [promoApplied, setPromoApplied] = useState(false);
  const [promoDetails, setPromoDetails] = useState<null | {
    code: string;
    originalPrice: number;
    discountedPrice: number;
    savings: number;
  }>(null);

  useEffect(() => {
    if (pickup && dropoff && selectedService) {
      setShouldCalculate(true);
    }
  }, [pickup, dropoff, selectedService, isRoundTrip]);

  const updateMinDateTime = useCallback(() => {
    const now = new Date();
    now.setHours(now.getHours() + 24); // Set to 24 hours from now
    const minDate = now.toISOString().split('T')[0];
    const minTime = now.toTimeString().slice(0, 5);
    setMinDate(minDate);
    setMinTime(minTime);
    setDate(minDate);
    setTime(minTime);
  }, []);

  const handleCalculationStart = useCallback(() => {
    setIsCalculating(true);
    setError(null);
  }, []);

  useEffect(() => {
    console.log("Current user:", user);
    updateMinDateTime();
    const editRideDataString = localStorage.getItem('editRideData');
    if (editRideDataString) {
      const editRideData = JSON.parse(editRideDataString);
      setIsEditing(true);
      setOriginalRideDetails(editRideData);
      setPickup(editRideData.pickup || '');
      setDropoff(editRideData.dropoff || '');
      setDate(editRideData.date || '');
      setTime(editRideData.time || '');
      setIsRoundTrip(editRideData.isRoundTrip || false);
      setReturnTime(editRideData.returnTime || '');
      setAdditionalAssistance(editRideData.additionalAssistance || '');
      setQuote(editRideData.quote || null);
      setOriginalQuote(editRideData.originalQuote || null);
      setRideId(editRideData.id || null);
      localStorage.removeItem('editRideData');
    } else {
      const duplicatedRideDataString = localStorage.getItem('duplicatedRideData');
      if (duplicatedRideDataString) {
        const duplicatedRideData = JSON.parse(duplicatedRideDataString);
        setPickup(duplicatedRideData.pickup || '');
        setDropoff(duplicatedRideData.dropoff || '');
        setDate(duplicatedRideData.date || '');
        setTime(duplicatedRideData.time || '');
        setIsRoundTrip(duplicatedRideData.isRoundTrip || false);
        setReturnTime(duplicatedRideData.returnTime || '');
        setAdditionalAssistance(duplicatedRideData.additionalAssistance || '');
        localStorage.removeItem('duplicatedRideData');
      }
    }
  }, [user, updateMinDateTime]);

  useEffect(() => {
    const fetchRideServices = async () => {
      const servicesSnapshot = await getDocs(collection(db, 'rideServices'));
      const servicesList = servicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as RideService));
      setRideServices(servicesList);
      // Remove the default selection
      // if (servicesList.length > 0) {
      //   setSelectedService(servicesList[0]);
      // } else {
      //   setError("No ride services available. Please try again later.");
      // }
    };

    fetchRideServices();
  }, []);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);

    const now = new Date();
    const minDate = now.toISOString().split('T')[0];
    const minTime = now.toTimeString().slice(0, 5);

    if (selectedDate === minDate && time < minTime) {
      setTime(minTime);
    }
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDateTime = new Date(`${date}T${e.target.value}`);
    const minDateTime = new Date(`${minDate}T${minTime}`);
    if (selectedDateTime >= minDateTime) {
      setTime(e.target.value);
    } else {
      setError("Please select a time at least 24 hours from now.");
    }
  };

  const handleReturnTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReturnTime(e.target.value);
  };

  const [isPromoClicked, setIsPromoClicked] = useState(false); // New state to track if Apply was clicked

  const handleQuoteCalculated = useCallback((
    calculatedQuote: number | null, 
    calculatedOriginalQuote: number | null, 
    error: string | null,
    calculatedDistance: number | null,
    calculatedDuration: number | null,
    promoCodeDetails: any | null
  ) => {
    setQuote(calculatedQuote);
    setOriginalQuote(calculatedOriginalQuote);
    setDistance(calculatedDistance);
    setDuration(calculatedDuration);
    setIsCalculating(false);
    setIsApplyingPromo(false);
  
    // Handle promo code results
    if (isPromoClicked) { // Only show error if Apply was clicked
      if (promoCodeDetails) {
        setAppliedPromoDetails(promoCodeDetails);
        setError(null);
      } else if (promoCode) {
        setError("Invalid promo code");
      }
    }
  }, [isPromoClicked, promoCode]);
  
  const handleApplyPromo = async () => {
    if (promoApplied) {
      setError("Promo code already applied");
      return;
    }

    setError("");

    if (!promoCode) {
      setError("Please enter a promo code");
      return;
    }

    if (quote === null) {
      setError("Please calculate a quote before applying a promo code");
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/rides/apply-promo`, {
        promoCode,
        quote,
      });

      if (response.data.discountedQuote) {
        setOriginalQuote(quote);
        setQuote(response.data.discountedQuote);
        setPromoApplied(true);
        setPromoDetails({
          code: promoCode,
          originalPrice: quote,
          discountedPrice: response.data.discountedQuote,
          savings: quote - response.data.discountedQuote,
        });
      } else {
        setError("Invalid promo code");
      }
    } catch (error) {
      console.error("Error applying promo code:", error);
      setError("Error applying promo code. Please try again.");
    }
  };

  const hasRideDetailsChanged = () => {
    if (!originalRideDetails) return true;

    return (
      pickup !== originalRideDetails.pickup ||
      dropoff !== originalRideDetails.dropoff ||
      date !== originalRideDetails.date ||
      time !== originalRideDetails.time ||
      isRoundTrip !== originalRideDetails.isRoundTrip ||
      returnTime !== originalRideDetails.returnTime ||
      additionalAssistance !== originalRideDetails.additionalAssistance ||
      selectedService?.id !== originalRideDetails.selectedServiceId ||
      quote !== originalRideDetails.quote
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Submit button clicked");

    if (!user) {
      setError("User not authenticated. Please log in.");
      return;
    }

    if (!selectedService) {
      setError("Please select a ride service");
      return;
    }

    if (isEditing && !hasRideDetailsChanged()) {
      console.log("No changes detected, skipping update");
      onSubmit(originalRideDetails!);
      return;
    }

    if (quote === null) {
      setError("Please calculate a quote before proceeding.");
      return;
    }

    console.log("Proceeding to payment step");

    try {
      const rideData: RideDetails = {
        userId: user.uid,
        pickup,
        dropoff,
        date,
        time,
        isRoundTrip,
        additionalAssistance,
        selectedServiceId: selectedService?.id || null,
        quote: quote || 0,
        originalQuote: originalQuote || null,
        status: isEditing ? 'updated' : 'pending',
        distance: distance || 0,
        duration: duration || 0,
        serviceName: selectedService?.name || 'Unknown',
      };

      if (isRoundTrip && returnTime) {
        rideData.returnTime = returnTime;
      }

      if (isEditing && rideId) {
        // Update existing ride
        await updateDoc(doc(db, 'rides', rideId), {
          ...Object.fromEntries(
            Object.entries(rideData).map(([key, value]) => [key, value])
          )
        });
        console.log("Ride updated successfully");
        rideData.id = rideId;
        onSubmit(rideData);
      } else {
        // Create new ride
        const rideRef = await addDoc(collection(db, 'rides'), rideData);
        const generatedRideId = rideRef.id;
        setRideId(generatedRideId);
        rideData.id = generatedRideId;
        setRideDetails(rideData);
        setBookingStep('payment');
      }
    } catch (error) {
      console.error("Error creating/updating ride document:", error);
      setError("Failed to create/update ride. Please try again.");
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={6} sx={{ mt: 8, mb: 4, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h4" gutterBottom>
          {bookingStep === 'details' ? 'Book a Ride' : 'Payment'}
        </Typography>
        {bookingStep === 'details' ? (
          <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', mt: 3 }}>
            <LocationInput
              id="pickup"
              value={pickup}
              onChange={setPickup}
              placeholder="Enter pickup location"
              aria-describedby="pickup-description"
              label="Pickup Location"
            />
            <Typography variant="caption" id="pickup-description" sx={{ mb: 2, display: 'block' }}>
              Enter the address where you'd like to be picked up
            </Typography>

            <LocationInput
              id="dropoff"
              value={dropoff}
              onChange={setDropoff}
              placeholder="Enter dropoff location"
              aria-describedby="dropoff-description"
              label="Dropoff Location"
            />
            <Typography variant="caption" id="dropoff-description" sx={{ mb: 2, display: 'block' }}>
              Enter the address of your destination
            </Typography>

            <TextField
              fullWidth
              label="Date"
              type="date"
              value={date}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: minDate }}
              sx={{ mb: 2 }}
            />

            <TextField
              fullWidth
              label="Time"
              type="time"
              value={time}
              onChange={handleTimeChange}
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: date === minDate ? minTime : undefined }}
              sx={{ mb: 2 }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={isRoundTrip}
                  onChange={(e) => setIsRoundTrip(e.target.checked)}
                />
              }
              label="Round Trip"
              sx={{ mb: 2 }}
            />

            {isRoundTrip && (
              <TextField
                fullWidth
                label="Return Time"
                type="time"
                value={returnTime}
                onChange={handleReturnTimeChange}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
              />
            )}

            <TextField
              fullWidth
              label="Additional Assistance"
              multiline
              rows={4}
              value={additionalAssistance}
              onChange={(e) => setAdditionalAssistance(e.target.value)}
              placeholder="Describe any additional assistance you need"
              sx={{ mb: 2 }}
            />

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="ride-service-label">Ride Service</InputLabel>
              <Select
                labelId="ride-service-label"
                value={selectedService?.id || ''}
                onChange={(e) => {
                  const selected = rideServices.find(s => s.id === e.target.value);
                  setSelectedService(selected || null);
                }}
                label="Ride Service"
              >
                <MenuItem value="">
                  <em>Select a service</em>
                </MenuItem>
                {rideServices.map((service) => (
                  <MenuItem key={service.id} value={service.id}>{service.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  label="Promo Code"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                  disabled={promoApplied}
                  error={!!promoError}
                  helperText={promoError}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleApplyPromo}
                  disabled={promoApplied || !promoCode}
                  sx={{ height: '56px' }} // Match the default height of MUI TextField
                >
                  Apply
                </Button>
              </Grid>
              {promoApplied && promoDetails && (
                <Grid item xs={12}>
                  <Box sx={{ bgcolor: 'success.main', color: 'success.contrastText', p: 2, borderRadius: 1, mt: 2 }}>
                    <Typography variant="h6">Promo Applied: {promoDetails.code}</Typography>
                    <Typography>Original Price: ${promoDetails.originalPrice.toFixed(2)}</Typography>
                    <Typography>Discounted Price: ${promoDetails.discountedPrice.toFixed(2)}</Typography>
                    <Typography>You Save: ${promoDetails.savings.toFixed(2)}</Typography>
                  </Box>
                </Grid>
              )}
            </Grid>

            <QuoteCalculator
              pickup={pickup}
              dropoff={dropoff}
              isRoundTrip={isRoundTrip}
              onQuoteCalculated={handleQuoteCalculated}
              onCalculationStart={handleCalculationStart}
              selectedService={selectedService}
              promoCode={promoCode}
              shouldCalculate={shouldCalculate}
            />

            {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}

            {isCalculating ? (
              <Typography sx={{ mb: 2 }}>Calculating quote...</Typography>
            ) : (
              <Button 
                type="submit" 
                fullWidth
                variant="contained" 
                color="primary" 
                disabled={!pickup || !dropoff || quote === null}
                sx={{ mt: 3, mb: 2, py: 1.5, fontSize: '1.1rem' }}
              >
                {isEditing && !hasRideDetailsChanged()
                  ? 'Save (No Changes)'
                  : quote !== null
                    ? `${isEditing ? 'Update' : 'Proceed to Payment'} ($${quote.toFixed(2)})`
                    : 'Calculate Quote'}
              </Button>
            )}
          </Box>
        ) : rideDetails ? (
          <Elements stripe={stripePromise}>
            <PaymentStep 
              amount={quote || 0} 
              rideDetails={{...rideDetails, id: rideDetails.id || ''}}
              onPaymentComplete={(paymentMethodId, rideId) => {
                if (rideDetails) {
                  const updatedRideDetails = { ...rideDetails, id: rideId, paymentMethodId, status: 'paid' };
                  onSubmit(updatedRideDetails);
                }
              }} 
            />
          </Elements>
        ) : (
          <Typography>Error: Ride details not available</Typography>
        )}
      </Paper>
    </Container>
  );
};

export default RideForm;

function setPromoApplied(arg0: boolean) {
  throw new Error('Function not implemented.');
}


function setCalculatedQuote(discountedQuote: any) {
  throw new Error('Function not implemented.');
}


function setPromoDetails(arg0: { code: string; originalPrice: any; discountedPrice: any; savings: number; }) {
  throw new Error('Function not implemented.');
}