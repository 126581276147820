import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Card, CardContent, Typography, Button, Box, Divider, 
  Container, Grid, Chip, useTheme, useMediaQuery, Tabs, Tab 
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { collection, query, where, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../context/AuthContext';
import { format, parseISO } from 'date-fns';
import { getFunctions, httpsCallable } from 'firebase/functions';
// import EditIcon from '@mui/icons-material/Edit';

interface RideProps {
  id: string;
  pickup: string;
  dropoff: string;
  date: string;
  time: string;
  isRoundTrip: boolean;
  returnTime?: string;
  additionalAssistance: string;
  quote: number;
  status: 'pending' | 'approved' | 'completed' | 'cancelled';
  serviceName: string; // Added this line to include the service name
}

const MyRides: React.FC = () => {
  const [rides, setRides] = useState<RideProps[]>([]);
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const functions = getFunctions();

  useEffect(() => {
    if (user) {
      const q = query(collection(db, 'rides'), where('userId', '==', user.uid));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const fetchedRides = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as RideProps));
        console.log('Fetched Rides:', fetchedRides); // Added this line
        setRides(fetchedRides);
      });
  
      return () => unsubscribe();
    }
  }, [user]);

  
  const formatDateTime = (date: string, time: string) => {
    const dateTime = parseISO(`${date}T${time}`);
    return format(dateTime, "EEEE, MMMM do, yyyy 'at' h:mm a");
  };

  const handleCancelRide = async (rideId: string) => {
    if (window.confirm('Are you sure you want to cancel this ride?')) {
      try {
        // Update the ride's status in Firestore
        await updateDoc(doc(db, 'rides', rideId), { status: 'cancelled' });

        // Call the Cloud Function to notify Slack
        const notifySlackOnCancel = httpsCallable(functions, 'notifySlackOnCancel');
        await notifySlackOnCancel({ rideId });

        console.log('Ride cancelled successfully and Slack notified');
      } catch (error) {
        console.error('Error cancelling ride: ', error);
      }
    }
  };

  const handleDuplicateRide = (ride: RideProps) => {
    const { id, ...rideData } = ride;
    localStorage.setItem('duplicatedRideData', JSON.stringify(rideData));
    navigate('/book-ride');
  };

  // const handleEditRide = (ride: RideProps) => {
  //   localStorage.setItem('editRideData', JSON.stringify(ride));
  //   navigate('/book-ride');
  // };

  const getStatusColor = (status: RideProps['status'] | undefined) => {
    switch (status) {
      case 'pending': return 'warning';
      case 'approved': return 'success';
      case 'completed': return 'info';
      case 'cancelled': return 'error';
      default: return 'default';
    }
  };

  const filteredRides = rides.filter(ride => {
    if (tabValue === 0) return ride.status !== 'cancelled';
    if (tabValue === 1) return ride.status === 'pending';
    if (tabValue === 2) return ride.status === 'approved';
    if (tabValue === 3) return ride.status === 'completed';
    if (tabValue === 4) return ride.status === 'cancelled';
    return true;
  });

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4, fontWeight: 'bold', color: theme.palette.primary.main }}>
        My Rides
      </Typography>
      <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)} sx={{ mb: 3 }}>
        <Tab label="All Active" />
        <Tab label="Pending" />
        <Tab label="Approved" />
        <Tab label="Completed" />
        <Tab label="Cancelled" />
      </Tabs>
      <Grid container spacing={3}>
        {filteredRides.map((ride) => (
          <Grid item xs={12} key={ride.id}>
            <Card elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
              <CardContent sx={{ p: 0 }}>
                <Box sx={{ p: 2, backgroundColor: theme.palette.primary.main, color: 'white' }}>
                  <Typography variant="h6" component="h2" gutterBottom>
                    <DirectionsCarIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                    Ride on {formatDateTime(ride.date, ride.time)}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  {ride.status && (
                    <Chip 
                      label={ride.status.toUpperCase()} 
                      color={getStatusColor(ride.status)}
                      sx={{ mb: 1 }}
                    />
                  )}
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <LocationOnIcon color="primary" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    From: {ride.pickup}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <LocationOnIcon color="primary" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    To: {ride.dropoff}
                  </Typography>
                  <Chip 
                    label={ride.isRoundTrip ? "Round Trip" : "One Way"} 
                    color={ride.isRoundTrip ? "secondary" : "default"}
                    size="small"
                    sx={{ mb: 1 }}
                  />
                  {ride.isRoundTrip && ride.returnTime && (
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      <AccessTimeIcon color="action" sx={{ mr: 1, verticalAlign: 'middle', fontSize: '0.9rem' }} />
                      Return: {formatDateTime(ride.date, ride.returnTime)}
                    </Typography>
                  )}
                  {ride.additionalAssistance && (
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Additional Assistance: {ride.additionalAssistance}
                    </Typography>
                  )}
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Service: {ride.serviceName} {/* Added this line to display the service name */}
                  </Typography>
                  <Typography variant="h6" sx={{ mt: 2, color: theme.palette.success.main }}>
                    Quote: ${ride.quote.toFixed(2)}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 1 }}>
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<CancelIcon />}
                      onClick={() => handleCancelRide(ride.id)}
                      sx={{ flexGrow: isMobile ? 1 : 0, mb: isMobile ? 1 : 0 }}
                    >
                      Cancel Ride
                    </Button>
                    {/*
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<EditIcon />}
                      onClick={() => handleEditRide(ride)}
                      sx={{ flexGrow: isMobile ? 1 : 0, mb: isMobile ? 1 : 0 }}
                    >
                      Edit Ride
                    </Button>
                    */}
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<FileCopyIcon />}
                      onClick={() => handleDuplicateRide(ride)}
                      sx={{ flexGrow: isMobile ? 1 : 0 }}
                    >
                      Duplicate Ride
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default MyRides;